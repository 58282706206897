import Vue from 'vue'
import Vuex from 'vuex';

Vue.use(Vuex);


export default new Vuex.Store({
    state: {
        barcodeReader: null,
        barcodeReaderData: {},
        /*barcodeReader: {
            readers: null,
            reader: null,
            readerAutoClosed: false,
            scannerName: null,
            hidden: null,
            visibilityChange: null
        },*/
        login: {
            iduser: null,
            operatore: null
        },
        company: {
            code: false
        },
        modules: {
        },
        warehouses: {

        },
        menu: {
            enabled: true,
            insertArea: false,// area inserimento del modulo
            rowsArea: false,// area righe movimento del modulo
            confirmArea: false,// ?
            keydownPageEnabled: true
        },
        max_len_note: 70,
        logx_dialog: function(tipo, livello, title, message, callback) {
            //logx_dialog(tipo,title,message,callback) funzione
            //tipo     : confirm, alert
            //title    : string
            //message  : html
            //callback : funzione
            var main = document.querySelector('main'),
                dialog = document.createElement('div');
            //main.removeChild(document.querySelectorAll('.wrapper-dialog'));
            dialog.classList.add('wrapper-dialog');
            dialog.innerHTML = '\n' +
                '    <div class="backdrop-dialog open"></div>\n' +
                '    <div id="modal-dialog" class="' + tipo + ' open" tabindex="-1">\n' +
                '        <div class="content">\n' +
                '            <div class="header '+livello +'">\n' +
                '\n' +
                '            </div>\n' +
                '\n' +
                '            <div class="title">\n' + title +
                '\n' +
                '            </div>\n' +
                '            <div class="message">\n' + message +
                '\n' +
                '            </div>\n' +
                '            <div class="actions">\n' +
                '                <button id="okButton" class="logx btn btn-primary">Ok</button>\n' +
                (tipo==='confirm'?'<button id="cancelButton" class="logx btn btn-secondary">Annulla</button>\n':'') +
                '            </div>\n' +
                '           <div class="clearfix"></div>\n' +
                '        </div>\n' +
                '    </div>';
            main.append(dialog);
            var dg = document.getElementById('modal-dialog');
            dg.focus()
            document.querySelector('body').classList.add('dialog-open');
            var okButton = document.getElementById("okButton"),
                cancelButton = document.getElementById("cancelButton")

            if(tipo === 'confirm') {
                if (typeof callback === "function") {
                    okButton.addEventListener('click', callback);
                } else {
                    okButton.addEventListener('click', this.closeDialog);
                }
                cancelButton.addEventListener('click', this.closeDialog);
            } else {
                okButton.addEventListener('click', this.closeDialog)
            }
            dg.addEventListener('keydown', this.keyDialog)
        },
        closeDialog: function(evt) {
            evt.stopPropagation()
            var main = document.querySelector('main');
            main.removeChild(document.querySelector('.wrapper-dialog'));
            console.log('Ci sei?', document.querySelector('div.dettagli-riga.open input'))
            setTimeout(() => document.querySelector('div.dettagli-riga.open input').focus() , 500)

        },
        keyDialog: function(evt) {
            evt.stopPropagation()
            console.log('keyDialog', document.activeElement, evt.key)
            switch (evt.key) {
                case 'Enter':
                    document.getElementById("okButton").click()
                    break;
                case 'Backspace':
                    document.getElementById("cancelButton").click()
                    break;
                default:
            }
        },

        /*
        //Funzioni del BarcodeReader
        setupBarcodeReader: function(BarCode) {
            // Create a BarcodeReaders object to query the list of available readers later.
            this.barcodeReader.readers = BarCode;

            // Check whether the browser supports detection of the web page visibility.
            if (typeof document.webkitHidden !== "undefined") { // Android 4.4 Chrome
                this.barcodeReader.hidden = "webkitHidden";
                this.barcodeReader.visibilityChange = "webkitvisibilitychange";
            }
            else if (typeof document.hidden !== "undefined") { // Standard HTML5 attribute
                this.barcodeReader.hidden = "hidden";
                this.barcodeReader.visibilityChange = "visibilitychange";
            }

            if (this.barcodeReader.hidden && typeof document.addEventListener !== "undefined" && typeof document[this.barcodeReader.hidden] !== "undefined") {
                // Add an event listener for the visibility change of the web page.
                document.addEventListener(this.barcodeReader.visibilityChange, this.handleVisibilityChange(BarCode), false);
            }
        },
        onBarcodeReadersComplete: function(result) {
            if (result.status === 0) {
                this.barcodeReader.readers.getAvailableBarcodeReaders(this.onGetAvailableBarcodeReadersComplete);
            }
            else {
                console.log('BarcodeReader Error: Failed to create BarcodeReaders',result.status, result.message);
            }
        },
        // After BarcodeReader object is created we can configure our symbologies and add our event listener
        onBarcodeReaderComplete: function(result) {
            if (result.status === 0) {
                console.log('BarcodeReader object successfully created');
                // Configure the symbologies needed. Buffer the settings and commit them at once.
                this.barcodeReader.reader.setBuffered("Symbology", "Code39", "Enable", "true", this.onSetBufferedComplete);
                this.barcodeReader.reader.setBuffered("Symbology", "Code128", "EnableCode128", "true", this.onSetBufferedComplete);
                this.barcodeReader.reader.setBuffered("Symbology", "EANUPC", "EnableEAN13", "true", this.onSetBufferedComplete);
                this.barcodeReader.reader.setBuffered("Symbology", "QRCode", "Enable", "true", this.onSetBufferedComplete);
                // abilitare anche la simbologi 2d DataMatrix
                this.barcodeReader.reader.commitBuffer(this.onCommitComplete);

                // Add an event handler to receive barcode data
                this.addRemoveBarcodeListener(true);
                // Add an event handler for the window's beforeunload event
                window.addEventListener("beforeunload", this.onBeforeUnload);
            }
            else {
                this.barcodeReader.reader = null;
                console.log('BarcodeReader Error: Failed to create BarcodeReader',result.status, result.message);
                alert('Failed to create BarcodeReader, ' + result.message);
            }
        },

        // Verify the symbology configuration
        onSetBufferedComplete: function(result) {
            if (result.status !== 0) {
                console.log('BarcodeReader Error: setBuffered failed',result.status, result.message, result.family, result.key, result.option);
            }
        },

        onCommitComplete: function(resultArray) {
            if (resultArray.length > 0) {
                for (var i = 0; i < resultArray.length; i++) {
                    var result = resultArray[i];
                    if (result.status !== 0) {
                        console.log('BarcodeReader Error: commitBuffer failed',result.status, result.message);
                        if (result.method === "getBuffered" || result.method === "setBuffered") {
                            console.log(result.family, result.key, result.option);
                        }
                    }
                } //endfor
            }
        },

        // Handle barcode data when available
        onBarcodeDataReady: function(data, type, time) {
            alert(JSON.stringify(data), type, time)
        },

        /**
         * If the browser supports visibility change event, we can close the
         * BarcodeReader object when the web page is hidden and create a new
         * instance of the BarcodeReader object when the page is visible. This
         * logic is used to re-claim the barcode reader in case another
         * application has claimed it when this page becomes hidden.
         */
        /*
         handleVisibilityChange: function(BarCode) {
            if (document[this.barcodeReader.hidden]) // The web page is hidden
            {
                this.closeBarcodeReader(true);
            }
            else // The web page is visible
            {
                if (this.barcodeReader.readerAutoClosed) {
                    // Note: If you switch to another tab and quickly switch back
                    // to the current tab, the following call may have no effect
                    // because the BarcodeReader may not be completely closed yet.
                    // Once the BarcodeReader is closed, you may use the Open Reader
                    // button to create a new BarcodeReader object.
                    this.openBarcodeReader(this.barcodeReader.scannerName, BarCode);
                }
            }
        },

        openBarcodeReader: function(scannerName, BarCode) {
            if (!this.barcodeReader.reader) {
                this.barcodeReader.reader = BarCode;
            }
        },

        closeBarcodeReader: function(isAutoClose) {
            if (this.barcodeReader.reader) {
                this.barcodeReader.readerAutoClosed = isAutoClose;
                this.barcodeReader.reader.close(function (result) {
                    if (result.status === 0) {
                        console.log('BarcodeReader successfully closed');
                        this.barcodeReader.reader = null;
                        window.removeEventListener("beforeunload", this.onBeforeUnload);
                    }
                    else {
                        console.log('BarcodeReader Error: Failed to close BarcodeReader',result.status, result.message);
                    }
                });
            }
        },

        activateBarcodeReader: function() {
            // The following call should cause the aimer to light up
            // and be ready to decode. This feature is often referred
            // to as starting the software trigger.
            this.barcodeReader.reader.activate(true, this.onActivateComplete);
        },

        deactivateBarcodeReader: function() {
            // The following call should deactivate the aimer. This is
            // often referred to as stopping the software trigger.
            this.barcodeReader.reader.activate(false, this.onDeactivateComplete);
        },

        addRemoveBarcodeListener: function(added) {
            if (added) {
                // Add an event handler for the barcodedataready event
                this.barcodeReader.reader.addEventListener("barcodedataready", this.onBarcodeDataReady, false);
                console.log('Added an event handler for the barcodedataready event');
            }
            else {
                // Remove an event handler for the barcodedataready event
                this.barcodeReader.reader.removeEventListener("barcodedataready", this.onBarcodeDataReady);
                console.log('Removed the event handler for the barcodedataready event');
            }
        },

        // Enables or disables the hardware trigger (scan) button. The user
        // will not be able to press the scan button to scan if it is disabled.
        enableTrigger: function(enabled) {
            this.barcodeReader.reader.enableTrigger(enabled, this.onEnableTriggerComplete);
        },

        onGetAvailableBarcodeReadersComplete: function(result) {
            console.log('Available Scanners: ', result)
            if (result.length != 0) {
                this.barcodeReader.scannerName = result[0];
            }
        },

        onActivateComplete: function(result) {
            if (result.status !== 0) {
                console.log('BarcodeReader Error: activate failed',result.status, result.message);
            }
        },

        onDeactivateComplete: function(result) {
            if (result.status !== 0) {
                console.log('BarcodeReader Error: deactivate failed',result.status, result.message);
            }
        },

        onEnableTriggerComplete: function(result) {
            if (result.status !== 0) {
                console.log('BarcodeReader Error: enableTrigger failed',result.status, result.message);
            }
        },

        onBeforeUnload: function(evt) {
            var message = "Please close BarcodeReader before leaving this page.";
            (evt || window.event).returnValue = message;
            return message;
        } */
    },
    getters: {

    },

    mutations: {
        setBarcodeReader(state, barcodeReader) {
            state.barcodeReader = barcodeReader;
        },
        setBarcodeReaderData(state, data) {
            state.barcodeReaderData = data;
        },
        showInsertArea(state) {
            state.menu.insertArea = true;
            state.menu.rowsArea = false;
            state.menu.confirmArea = false;
        },
        showRowsArea(state) {
            state.menu.rowsArea = true;
            state.menu.insertArea = false;
            state.menu.confirmArea = false;
        },
        showConfirmArea(state) {
            state.menu.confirmArea = true;
            state.menu.insertArea = false;
            state.menu.rowsArea = false;
        },
    },

    actions: {
    }
});