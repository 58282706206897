module.exports = {
    idApp : '1',
    chiaveApi : 'chiave-valida',
    idVersion : '1.0',
    modules : {
        '1' : 'inventari',
        '2' : 'movimentazioni',
        '3' : 'ricevimento',
        '4' : 'spedizioni',
        '5' : 'vendite'
    },
    apiUrl : {
        //proxy: 'http://applogistica.overcover.com/test-api'
        proxy: 'http://api_logistics.raddle.it/api',
    },
    testApiUrl : {
        proxy: 'http://applogistica.overcover.com/test-api'
    },
    apiEndpoints : {
        login: '/autenticazione/login',
        licenze: '/licenze',
        verifica_codice_prodotto: '/prodotti',
    },
    testEndpoints : {
        login: '/token.php',
        licenze: '/licenze.php',
        verifica_codice_prodotto: '/?',
    },
    x_auth_token : 'aaaaaaaa-bbbb-cccc-dddd-eeeeeeeeeeee'
}