<template>
  <div v-if="allLoad">
    <my-header></my-header>
    <main>
      <section id="menu-moduli">
        <div class="container">
          <div class="row text-center">
            <div class="col-12 pb-3">
              <p class="fs-8 ucase">Operatore: {{operatore}}</p>
            </div>
          </div>
          <form novalidate>
            <div class="row mt-2">
              <div class="col-12">
                <router-link v-for="(module, index) in allModules"
                             :disabled="module.active==='0'"
                             v-bind:class="{disabled: module.active==='0', active: module.focus}"
                             :key="module.id"
                             :to="module.url"
                             class="logx btn btn-block btn-large"
                             @click.once="module.active != 0">
                             {{module.short}}
                             <span class="user-key content">[ {{index+1}} ]</span>
                </router-link>
              </div>
            </div>
          </form>
        </div>
      </section>
    </main>
  </div>
</template>

<script>
  //Importo l'HEADER
  import Header from './components/Header'

  import store from "@/vuex/store"

  export default {
    name: "app",
    components:{
      'my-header':Header
    },
    methods: {
      appLogout: function() {
        localStorage.iduser = '';
        store.state.login.iduser = null;
        this.$router.push('/login');
      },
      checkKeyMenu: function(evt) {
        evt.preventDefault;
        var router = this.$router;
        Object.keys(this.allModules).forEach(index => {
          if((parseInt(index)+1)==evt.key && this.allModules[index].active==1 && !this.avoidDoubleClick) {
            this.avoidDoubleClick = true;
            var module = this.allModules[index];
            module.focus = true;
            setTimeout(function() {
              router.push(module.url);
            }, 100)
            evt.stopPropagation();
          }
        });
      }
    },
    data() {
      return {
        allModules: store.state.modules,
        allLoad: false,
        operatore: localStorage.operatore,
        avoidDoubleClick: false
      }
    },
    created: function() {
      localStorage.idmodule = '';
      if (store.state.login.iduser && store.state.login.iduser!=='') {
        this.allLoad = true;
      }
    },
    mounted: function() {
      document.addEventListener("keydown", this.checkKeyMenu)
    },
    beforeDestroy() {
      document.removeEventListener("keydown", this.checkKeyMenu)
    }
  }
  /*
  DIALOG EXAMPLE
  this.dialogConfirm = Vue.dialog.confirm(message).then(function() {
  f_ok
  }).catch(function() {
  f_cancel
  });
  this.interactiveElements.dialog_confirm = {open:true, focus:true};


  NOTIFICATION EXAMPLE
        showNotifError: function() {
        // Can accept an Object of options
        this.$toast.open({
          message: 'Errore madornale!',
          type: 'error',
          // all other options
        });
      },
      showNotifWarning: function() {
        this.$toast.open({
          message: 'Stai attento... ',
          type: 'warning'
        });
      }

  */
</script>
