<template>
  <router-view v-if="allLoad"/>
</template>

<script>
  //Importo il modulo Store
  import store from "@/vuex/store";
  const config = require('./logistix.config');

  export default {
    name: "app",
    data() {
      return {
        licenseValid: false,
        userid: null,
        allLoad: false
      }
    },
    methods: {
      // Controllo della licenza
      checkLicenza: function() {
        //console.log(config.testApiUrl.proxy + config.testEndpoints.licenze);
        fetch(config.apiUrl.proxy + config.apiEndpoints.licenze, {
          method: 'GET',
          headers: {
            'X-API-KEY': config.chiaveApi
          },
        })
        .then((response) => {
          if (response.ok) {
            return response.json()
          } else {
            switch (response.status) {
              case 404:// licenza o azienda inesistente
                this.$router.push('/errorlicense');
                this.allLoad = false;
                break;
              case 401:// licenza scaduta
                this.$router.push('/errorlicense');
                this.allLoad = false;
                break
              default:// per tutti gli altri errori
                this.$router.push('/errorlicense');
                this.allLoad = false;
            }
            if (response.status === 404) {
                this.$router.push('/errorlicense');
              this.allLoad = false;
            }
          }
        })
        .then((jsonData) => {
            store.state.company.code = jsonData.azienda.cod_azienda;
            store.state.modules = jsonData.moduli;
            store.state.options = jsonData.opzioni;
            store.state.warehouses = jsonData.azienda.magazzini;
            this.licenseValid = true;
            console.log('jsonData',jsonData);
            //invia al login
            this.checkLogin();
        })
      },

      //Funzione per controllare la licenza senza le api, se non funzionano
      checkLicenzaNoApi: function() {
        store.state.company.code = '1';
        store.state.modules =
        {
          'id' : '5',
          'name' : 'Vendite a banco',
          'short' : 'Vendite a banco',
          'url' : '/moduli/vendite',
          'key' : '5',
          'active' : '1',
          'prefs' : {
            'note' : true,
            'mod_conta' : 'add' //add aggiunge, subst sostituisce
          }
        };
        store.state.options = {
          'id' : '5',
          'name' : 'Vendite a banco',
          'short' : 'Vendite a banco',
          'url' : '/moduli/vendite',
          'key' : '5',
          'active' : '1',
          'prefs' : {
            'note' : true,
            'mod_conta' : 'add' //add aggiunge, subst sostituisce
          }
        }
        store.state.warehouses = {

        };
        this.licenseValid = true;

        //Check login
        this.checkLogin();
      },

      //Funzione per controllare se utente già loggato e in sessione
      checkLogin: function() {
        if(store.state.login.iduser!==null && store.state.login.iduser!=='') {
          this.userid = store.state.login.iduser;
        }
        else if(localStorage.iduser && localStorage.iduser !== '') {
          this.userid = localStorage.iduser;
          store.state.login.iduser = this.userid;
        }
        else {
          localStorage.iduser = '';
          if(this.$route.fullPath !== '/login') {
            this.$router.push('/login');
          }
        }

        //Check modulo
        if (this.userid) {
          this.checkModule();
        }
      },

      //Funzione per ritornare al modulo attivo se viene effettuato il refresh della pagina
      checkModule: function() {
        if(localStorage.idmodule && localStorage.idmodule !== '') {
          if(this.$route.fullPath!==store.state.modules[localStorage.idmodule].url)
          {
            this.$router.push(store.state.modules[localStorage.idmodule].url);
          }
        }
        else if(this.$route.fullPath!=='/') {
          this.$router.push('/');
        }
      },

      //Funzione per visualizzare il caricamento sullo schermo
      loadingScreen: function(loading) {
        if(loading) {
          this.$router.push('/loading');
          this.allLoad = true;
        }
        else {
          this.allLoad = true;
        }
      }
    },
    mounted: function() {
      //Setto il localStorage del browser con le configurazioni di base se non è ancora settato
      if(!localStorage.iduser) {
        localStorage.iduser = '';
        localStorage.idmodule = '';
      }

      //Visualizzo il caricamento
      this.loadingScreen(true);

      //Check licenza
      if(!store.state.company.code) {
        this.checkLicenza();
      }
    }
  }
</script>
