<template>
  <div id="header" @click="blur" @focus="blur">
    <div class="header-title">{{menuTitle}}</div>
    <div class="hamburger-menu" @click="toggleHeaderMenu">
      <div class="user-key f1"></div>
      <div class="bar"></div>
      <div class="bar"></div>
      <div class="bar"></div>
    </div>
    <nav id="menu-nav">
      <ul>
        <li v-if="menuType=='moduli'">
          <span class="menu-item" @click="insertArea">Area inserimento dati<span class="user-key digit1"></span></span>
        </li>
        <li v-if="menuType=='moduli'">
          <span class="menu-item" @click="rowsArea">Area righe movimenti<span class="user-key digit2"></span></span>
        </li>
        <li v-if="menuType=='moduli'">
          <span class="menu-item" @click="suspendWork">Sospendi<span class="user-key backspace"></span></span>
        </li>
        <li v-if="menuType=='moduli'">
          <span class="menu-item" @click="confirmArea">Uscita<span class="user-key enter"></span></span>
        </li>
        <li v-if="menuType=='menu'">
          <span class="menu-item" @click="appLogout">Logout<span class="user-key enter"></span></span>
        </li>
      </ul>
    </nav>
  </div>
</template>

<style>
.nav {
  width:100%;
}
</style>

<script>
//Importo lo store
import store from "@/vuex/store";

export default {
  name: "app",
  methods: {
    dialogOk: function() {
      console.log('OK');
    },
    dialogCancel: function() {
      console.log('no');
    },
    appLogout: function() {
      localStorage.iduser = '';
      store.state.login.iduser = null;
      this.toggleHeaderMenu();
      this.$router.push('/login');
    },
    goBack: function() {
      localStorage.idmodule = '';
      this.$router.push('/');
      this.toggleHeaderMenu();
    },
    insertArea: function() {
      this.toggleHeaderMenu();
      store.commit("showInsertArea")
    },
    rowsArea: function() {
      this.toggleHeaderMenu();
      store.commit("showRowsArea")
    },
    confirmArea: function() {
      this.toggleHeaderMenu();
      store.commit("showConfirmArea");
    },
    suspendWork: function() {
      this.toggleHeaderMenu();
      localStorage.idmodule = '';
      this.$router.push('/');
    },
    toggleHeaderMenu: function() {
      if (document.getElementById('menu-nav').classList.item('open') === null) {
        document.getElementsByClassName('header-backdrop')[0].classList.add('open');
        document.getElementById('menu-nav').classList.add('open');
        store.state.menu.keydownPageEnabled = false;
      } else {
        document.getElementsByClassName('header-backdrop')[0].classList.remove('open');
        document.getElementById('menu-nav').classList.remove('open');
        store.state.menu.keydownPageEnabled = true;
      }
      this.headerMenuOpen = !this.headerMenuOpen;
      //console.log('headerMenuOpen', this.headerMenuOpen);
    },
    checkKey: function(evt) {
      console.log('Keydown Header.vue', evt.key, 'checkKey()')
      //console.log(store.state.menu.enabled,evt.key);
      if (evt.key === 'F1' && store.state.menu.enabled) {
        evt.preventDefault();
        evt.stopImmediatePropagation()
        evt.stopPropagation();
        this.toggleHeaderMenu();
      }
      else if(this.headerMenuOpen && store.state.menu.enabled) {
        evt.preventDefault();
        switch (evt.key) {
          case '1':
            this.insertArea();
            break;
          case '2':
            this.rowsArea();
            break;
          case 'Backspace':
            this.suspendWork();
            break;
          case 'Enter':
            //alert(this.menuType);
            if(this.menuType=='moduli') {
              this.confirmArea();
            }
            else {
              this.appLogout();
            }
            break;
          default:
            return false;
        }
        evt.stopPropagation();
      }
    },
    blur: function(evt) {
      evt.preventDefault();
      console.log('blur');
      return false;
    }
  },
  data() {
    return {
      allModules: store.state.modules,
      menuTitle: 'Login',
      menuType: 'login',
      headerMenuOpen: false
    }
  },
  created: function() {
    if(store.state.login.iduser && store.state.login.iduser!=='') {
      this.menuTitle = 'Menu';
      this.menuType = 'menu';
    }
    if(localStorage.idmodule && localStorage.idmodule!='') {
      if(this.allModules[localStorage.idmodule]) {
        this.menuTitle = this.allModules[localStorage.idmodule].name;
        this.menuType = 'moduli';
      }
    }
  },
  mounted: function() {
    window.addEventListener('keydown', this.checkKey);
  },
  beforeDestroy: function() {
    window.removeEventListener("keydown", this.checkKey);
  }
}
</script>