import Vue from 'vue'
import App from './App.vue'

//Root css
import './assets/css/logistix-root.css';

//DIALOG
//import VueConfirmDialog from 'vue-confirm-dialog'
//Vue.use(VueConfirmDialog)
//Vue.component('vue-confirm-dialog', VueConfirmDialog.default)

// include the default style
import './assets/css/logistix-dialog.css';

//NOTIFICATION
import VueToast from 'vue-toast-notification';
// Import any of available themes
import './assets/css/logistix-notifications.css';
//import 'vue-toast-notification/dist/theme-default.css';

//BOOTSTRAP
import { BootstrapVue } from 'bootstrap-vue'
import './assets/css/logistix-bootstrap.css'
import './assets/css/logistix-bootstrap-vue.css'
import Vuex from 'vuex';
import store from './vuex/store'

import './assets/css/logistix.css'
import VueRouter from 'vue-router'
import router from './router'
import { MyBarcodeReader } from './barcode-reader/barcode-reader'


Vue.use(VueToast);

// Install BootstrapVue
Vue.use(BootstrapVue)

// Optionally install the BootstrapVue icon components plugin
//Vue.use(IconsPlugin)
//Bootstrap css

//VUEX
Vue.use(Vuex);

Vue.use(VueRouter)
//Nel caso di compatibilità necessaria con internet explorer installare promise ie6

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
  methods: {
    setBarcode: function() {
      const barcodeReaderFactory = new MyBarcodeReader()

      barcodeReaderFactory.setup();
    }
  },
  created: function() {
    this.setBarcode();
  }
}).$mount('#app')

