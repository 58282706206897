import store from '../vuex/store'

export class MyBarcodeReader {

    readers = null
    barcodeReader = null
    readerAutoClosed = false
    scannerName = null
    hidden = false
    visibilityChange = null 
    setupCallback = null

    setup () {


        // Create a BarcodeReaders object to query the list of available readers later.
        this.readers = new window.BarcodeReaders(this.onBarcodeReadersComplete.bind(this));

        // Check whether the browser supports detection of the web page visibility.
        /*
        if (typeof document.webkitHidden !== "undefined") { // Android 4.4 Chrome
            hidden = "webkitHidden";
            visibilityChange = "webkitvisibilitychange";
        }
        else if (typeof document.hidden !== "undefined") { // Standard HTML5 attribute
            hidden = "hidden";
            visibilityChange = "visibilitychange";
        }

        if (hidden && typeof document.addEventListener !== "undefined" && typeof document[hidden] !== "undefined") {
            // Add an event listener for the visibility change of the web page.
            document.addEventListener(visibilityChange, handleVisibilityChange, false);
        }
        */
    }

    onBarcodeReadersComplete (result) {
        console.log('result', result, this);

        if (result.status === 0) {
            this.readers.getAvailableBarcodeReaders(this.onGetAvailableBarcodeReadersComplete.bind(this));
        }
        else {
            console.log('BarcodeReader Error: Failed to create BarcodeReaders',result.status, result.message);
        }
    }

    // After BarcodeReader object is created we can configure our symbologies and add our event listener
    onBarcodeReaderComplete (result) {
        if (result.status === 0) {
            console.log('BarcodeReader object successfully created');

            // Configure the symbologies needed. Buffer the settings and commit them at once.
            this.barcodeReader.setBuffered("Symbology", "Code39", "Enable", "true", this.onSetBufferedComplete);
            this.barcodeReader.setBuffered("Symbology", "Code128", "EnableCode128", "true", this.onSetBufferedComplete);
            this.barcodeReader.setBuffered("Symbology", "EANUPC", "EAN13CheckDigit", "true", this.onSetBufferedComplete);
            this.barcodeReader.setBuffered("Symbology", "QRCode", "Enable", "true", this.onSetBufferedComplete);
            // abilitare anche la simbologi 2d DataMatrix
            
            
            //this.barcodeReader.enableTrigger(false);
            this.barcodeReader.addEventListener("barcodedataready", (code, type, time) => { 
                store.commit("setBarcodeReaderData", { code: code, type: type, time: time});
            });
            
            this.barcodeReader.commitBuffer(this.onCommitComplete);
            
            store.commit('setBarcodeReader', this.barcodeReader)

            // Add an event handler to receive barcode data
            this.addRemoveBarcodeListener(true);

            // Add an event handler for the window's beforeunload event
            window.addEventListener("beforeunload", this.onBeforeUnload);
        }
        else {
            this.barcodeReader = "null";
            console.log('BarcodeReader Error: Failed to create BarcodeReader',result.status, result.message);
        
        }
    }

    // Verify the symbology configuration
    onSetBufferedComplete (result) {
        if (result.status !== 0) {
            console.log('BarcodeReader Error: setBuffered failed',result.status, result.message, result.family, result.key, result.option);
        }
    }

    onCommitComplete (resultArray) {
        if (resultArray.length > 0) {
            for (var i = 0; i < resultArray.length; i++) {
                var result = resultArray[i];
                if (result.status !== 0) {
                    console.log('BarcodeReader Error: commitBuffer failed',result.status, result.message);
                    if (result.method === "getBuffered" || result.method === "setBuffered") {
                        console.log(result.family, result.key, result.option);
                    }
                }
            } //endfor
        }
    }

    
    openBarcodeReader (scannerName) {
        if (!this.barcodeReader) {
            this.barcodeReader = new window.BarcodeReader(scannerName, this.onBarcodeReaderComplete.bind(this));
        }
    }


    closeBarcodeReader (isAutoClose) {
        if (this.barcodeReader) {
            this.readerAutoClosed = isAutoClose;
            this.barcodeReader.close(function (result) {
                if (result.status === 0) {
                    console.log('BarcodeReader successfully closed');
                    this.barcodeReader = null;
                    window.removeEventListener("beforeunload", this.onBeforeUnload);
                }
                else {
                    console.log('BarcodeReader Error: Failed to close BarcodeReader',result.status, result.message);
                }
            });
        }
    }

    activateBarcodeReader () {
        // The following call should cause the aimer to light up
        // and be ready to decode. This feature is often referred
        // to as starting the software trigger.
        this.barcodeReader.activate(true, this.onActivateComplete);
    }

    deactivateBarcodeReader () {
        // The following call should deactivate the aimer. This is
        // often referred to as stopping the software trigger.
        this.barcodeReader.activate(false, this.onDeactivateComplete.bind(this));
    }

    addRemoveBarcodeListener (added) {
        if (added) {
            // Add an event handler for the barcodedataready event
            //barcodeReader.addEventListener("barcodedataready", onBarcodeDataReady, false);
            console.log('Added an event handler for the barcodedataready event');
        }
        else {
            // Remove an event handler for the barcodedataready event
            this.barcodeReader.removeEventListener("barcodedataready", this.onBarcodeDataReady);
            console.log('Removed the event handler for the barcodedataready event');
        }
    }

    // Enables or disables the hardware trigger (scan) button. The user
    // will not be able to press the scan button to scan if it is disabled.
    enableTrigger (enabled) {
        this.barcodeReader.enableTrigger(enabled, this.onEnableTriggerComplete);
    }

    onGetAvailableBarcodeReadersComplete (result) {

        if (result.length != 0) {
            this.scannerName = result[0];
            console.log('Available Scanners: ' + this.scannerName, this);
            this.openBarcodeReader(this.scannerName);
        }
    }

    onActivateComplete (result) {
        if (result.status !== 0) {
            console.log('BarcodeReader Error: activate failed',result.status, result.message);
        }
    }

    onDeactivateComplete (result) {
        if (result.status !== 0) {
            console.log('BarcodeReader Error: deactivate failed',result.status, result.message);
        }
    }

    onEnableTriggerComplete (result) {
        if (result.status !== 0) {
            console.log('BarcodeReader Error: enableTrigger failed',result.status, result.message);
        }
    }

    onBeforeUnload (evt) {
        var message = "Please close BarcodeReader before leaving this page.";
        (evt || window.event).returnValue = message;
        return message;
    }
}



