import Vue from 'vue'

//MENU
import Menu from '../Menu.vue'

//ROUTER
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Menu',
    component: Menu
  },
  {
    path: '/moduli/inventari',
    name: 'Inventari',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "inventari" */ '../moduli/Inventari.vue')
  },
  {
    path: '/moduli/movimentazioni',
    name: 'Movimentazioni',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "movimentazioni" */ '../moduli/Movimentazioni.vue')
  },
  {
    path: '/moduli/ricevimento',
    name: 'Ricevimento',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "ricevimento" */ '../moduli/Ricevimento.vue')
  },
  {
    path: '/moduli/spedizioni',
    name: 'Spedizioni',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "spedizioni" */ '../moduli/Spedizioni.vue')
  },
  {
    path: '/moduli/vendite',
    name: 'Vendite',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "vendite" */ '../moduli/Vendite.vue')
  },
  {
    path: '/login',
    name: 'Login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "login" */ '../Login.vue')
  },
  {
    path: '/loading',
    name: 'Caricamento',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "caricamento" */ '../Caricamento.vue')
  },
  {
    path: '/errorlicense',
    name: 'Errore licenza',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "errori-licenza" */ '../errors/Licenza.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    component: Menu
  }
]

const router = new VueRouter({
  routes,
  mode: 'history'
})

export default router